import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import "./cookiesmodal.scss";
import CookiesSettingsModal from "./CookiesSettingsModal";

const CookiesModal = () => {
  const [open, setOpen] = useState(false);
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted === "true") {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");

    setAcceptedCookies(true);
    if (cookiesAccepted) {
      setOpen(false);
      setShowSettingsModal(false);
    }
  };

  const closeSettingsModal = () => {
    setShowSettingsModal(false);
  };

  return (
    <>
      <Modal
        centered
        open={open}
        width={648}
        footer={null}
        closable={true}
        className="custom-modal"
        onCancel={() => setOpen(false)}
      >
        <div className="modal-container">
          <div style={{}} className="form-content">
            We use first-party cookies to provide you the best possible
            experience.
          </div>

          <div className="checkbox-container-iteams">
            <div class="checkbox-container">
              <input type="checkbox" id="cb1" disabled checked />
              <label for="cb1">Necessary</label>
            </div>
          </div>
          <div className="cookies-setting-container">
            <p>
              By clicking "Accept Cookies", you agree to the use of our cookies
              on your device.
            </p>
          </div>
          <div className="button-container">
            <button className="button button1" onClick={handleAcceptCookies}>
              Accept Cookies
            </button>
          </div>
        </div>
      </Modal>
      {/* <CookiesSettingsModal
        visible={showSettingsModal}
        onClose={closeSettingsModal}
        onAccept={handleAcceptCookies}
      /> */}
    </>
  );
};

export default CookiesModal;
