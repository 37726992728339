import React from "react";
import { Col, Row } from "antd";
import "./career.scss";

const Career = () => {
  return (
    <>
      <Row className="career-container">
        <Row className="career-header">
          <Col span={1}></Col>
          <Col span={5} className="career-header-text">
            <div>
              <p className="career-header-text-1">Looking for</p>
              <p className="career-header-text-2">An addition to our block</p>
              <p className="career-header-text-3">might be you!</p>
            </div>
            <div className="apply-now-link">
              <a
                href="https://perpetualblock.jobs.personio.com/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="button button1">Apply Now</button>
              </a>
            </div>
          </Col>
          <Col span={15}></Col>
        </Row>
        <Row className="company-objective">
          <h2>Who We Are</h2>
          <div className="company-objective-description">
            <p>
              At Perpetualblock AG, we believe that a diverse workplace is
              critical to our success. We are committed to creating an
              environment where everyone, regardless of their race, ethnicity,
              gender, age, religion, or disability, feels valued and respected.
            </p>
            <p>
              We strive to attract and retain a diverse workforce and provide
              opportunities for all employees to grow and develop their skills.
              We also recognize the importance of creating an inclusive culture
              where everyone can contribute their unique perspectives and
              ideas.Discrimination or harassment of any kind is not tolerated at
              Perpetualblock. We are committed to providing a safe and welcoming
              environment for all employees, customers, and partners.
            </p>
            <p>
              We recognize that creating a diverse workplace is an ongoing
              journey, and we are committed to continually learning and
              improving our practices. We welcome feedback from our employees
              and stakeholders and are committed to taking action to address any
              areas where we can improve.
            </p>
            <p>
              We believe that diversity is not only an important value here at
              Perpetualblock, but also critical to our success as an
              organization. We are committed to building a workplace that
              reflects the diverse communities we serve and creating an
              environment where everyone can thrive.
            </p>
          </div>
        </Row>
        <Row
          style={{
            display: "flex",
            paddingBottom: "2vh",
          }}
          className="offer-container"
        >
          <Row className="offer">
            <h2>What We Will Offer You</h2>
          </Row>

          <Row className="offer-details">
            <div className="offer-info">
              <span className="offer-icon">
                <img
                  src="/assets/offericon.png"
                  alt="offer-icon"
                  className="offer-icon-image"
                />
              </span>
              <span className="offer-text">A highly dynamic company</span>
            </div>
            <div className="offer-info">
              <span className="offer-icon">
                <img
                  src="/assets/offericon.png"
                  alt="offer-icon"
                  className="offer-icon-image"
                />
              </span>
              <span className="offer-text">
                Highly motivated international and interdisciplinary team
              </span>
            </div>
          </Row>
          <Row className="offer-details">
            <div className="offer-info">
              <span className="offer-icon">
                <img
                  src="/assets/offericon.png"
                  alt="offer-icon"
                  className="offer-icon-image"
                />
              </span>
              <span className="offer-text">
                Outstanding Technical Solutions To Sell And Impact
              </span>
            </div>
            <div className="offer-info">
              <span className="offer-icon">
                <img
                  src="/assets/offericon.png"
                  alt="offer-icon"
                  className="offer-icon-image"
                />
              </span>
              <span className="offer-text">
                An opportunity for personal and professional growth in a fast
                growing start-up environment
              </span>
            </div>
          </Row>
        </Row>
        <Row className="perpetualblock-team-heading">
          <h2>People And Culture</h2>
        </Row>

        <Row className="image-container">
          <img src="/assets/team.png" alt="teamPhoto" className="team-img" />
          <img src="/assets/PBEmployeee.png" alt="pbPhoto" className="pb-img" />
        </Row>
        <Row className="company-objective">
          <h2>Talent Pool</h2>
        </Row>
        <Row className="talent-pool-description">
          <p>
            We are always looking for talented and motivated individuals to join
            our team. If you don’t find a position that suits your needs, join
            our Talent Pool and our team will reach out to you if an opportunity
            that fits your profile opens up.
          </p>
        </Row>
        <Row className="talent-pool-button">
          <a
            href="https://perpetualblock.jobs.personio.com/job/1064734?display=en"
            target="_blank"
            rel="noreferrer"
          >
            <button className="button button1">Talent Pool</button>
          </a>
        </Row>
      </Row>
    </>
  );
};

export default Career;
