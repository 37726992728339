import React, { useState } from "react";
import { Drawer, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import "./headerFooter.scss";

const Header = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="header">
      <Drawer placement="right" width={"50%"} onClose={onClose} open={open}>
        <Menu mode="vertical" className="drawer-bar">
          <div className="drawer-main">
            <li key="home">
              <Link
                to="/"
                onClick={onClose}
                className={
                  location.pathname === "/"
                    ? "drawer-items-active"
                    : "drawer-items"
                }
              >
                Home
              </Link>
            </li>
            <li key="about">
              <Link
                to="/aboutus"
                onClick={onClose}
                className={
                  location.pathname.includes("aboutus")
                    ? "drawer-items-active"
                    : "drawer-items"
                }
              >
                About Us
              </Link>
            </li>
            <li key="projects">
              <Link
                to="/offerings"
                onClick={onClose}
                className={
                  location.pathname.includes("offerings")
                    ? "drawer-items-active"
                    : "drawer-items"
                }
              >
                Offerings
              </Link>
            </li>
            <li key="career">
              <Link
                to="/career"
                onClick={onClose}
                className={
                  location.pathname.includes("career")
                    ? "drawer-items-active"
                    : "drawer-items"
                }
              >
                Careers
              </Link>
            </li>
            <li key="resources">
              <Link
                to="/resources"
                onClick={onClose}
                className={
                  location.pathname.includes("resources")
                    ? "drawer-items-active"
                    : "drawer-items"
                }
              >
                Resources
              </Link>
            </li>
            <li key="contactus">
              <Link
                to="/contactus"
                onClick={onClose}
                className={
                  location.pathname.includes("contactus")
                    ? "drawer-items-active"
                    : "drawer-items"
                }
              >
                Contact Us
              </Link>
            </li>
          </div>
        </Menu>
      </Drawer>
      <Menu mode="horizontal" className="navbar">
        <li key="logo">
          <Link to="/">
            <img className="nav-logo" src={"/assets/logo.svg"} alt="logo" />
          </Link>
        </li>
        <div className="nav-main">
          <li key="home">
            <Link
              to="/"
              className={
                location.pathname === "/" ? "nav-items-active" : "nav-items"
              }
            >
              Home
            </Link>
          </li>
          <li key="about">
            <Link
              to={"/aboutus"}
              className={
                location.pathname.includes("aboutus")
                  ? "nav-items-active"
                  : "nav-items"
              }
            >
              About Us
            </Link>
          </li>
          <li key="offerings">
            <Link
              to="/offerings"
              className={
                location.pathname.includes("offerings")
                  ? "nav-items-active"
                  : "nav-items"
              }
            >
              Offerings
            </Link>
          </li>
          <li key="career">
            <Link
              to="/career"
              className={
                location.pathname.includes("career")
                  ? "nav-items-active"
                  : "nav-items"
              }
            >
              Careers
            </Link>
          </li>
          <li key="resources">
            <Link
              to="/resources"
              className={
                location.pathname.includes("resources")
                  ? "nav-items-active"
                  : "nav-items"
              }
            >
              Resources
            </Link>
          </li>
          <li key="contactus">
            <Link
              to="/contactus"
              className={
                location.pathname.includes("contactus")
                  ? "nav-items-active"
                  : "nav-items"
              }
            >
              Contact Us
            </Link>
          </li>
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div className="menu-border" onClick={() => showDrawer()}>
          <MenuOutlined size={40} className="mune-icon" />
        </div>
      </Menu>
    </div>
  );
};

export default Header;
