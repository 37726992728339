import React, { useState, useEffect, useRef } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Modal, Card, Button, Rate, Switch } from "antd";
import { Navigate } from "react-router-dom";
import { Collapse } from "antd";
import "./modaltypes.scss";

const CookieSetting = () => {
  const [btnText, setBtnText] = useState("Disabled");
  const [btnText2, setBtnText2] = useState("Disabled");
  const [open, setOpen] = useState(true);
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted === "true") {
      setOpen(false);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");

    setAcceptedCookies(true);
    if (cookiesAccepted) {
      setOpen(false);
    }
  };

  const backOut = () => {
    Navigate(-1);
  };

  const onChange = (checked) => {
    btnText === "Enabled" ? setBtnText("Disabled") : setBtnText("Enabled");
    console.log(`switch to ${checked}`);
  };
  const onChange2 = (checked) => {
    btnText2 === "Enabled" ? setBtnText2("Disabled") : setBtnText2("Enabled");
    console.log(`switch to ${checked}`);
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="accordion-card">
          <div>Necessary Cookies</div>
        </div>
      ),
      children: (
        <div className="accordion-table">
          <table className="table-container">
            <tr className="bold-heading">
              <th>Cookie</th>
              <th>Duration</th>
              <th>Description</th>
            </tr>
            <tr>
              <td> cookielawinfocheckbox-necessary</td>
              <td>11 months</td>
              <td>
                The cookie is set by GDPR cookie consent to record the user
                consent for the cookies in the category "Necessary".
              </td>
            </tr>
            <tr>
              <td>viewed_cookie_policy</td>
              <td>11 months</td>
              <td>
                The cookie is set by the GDPR Cookie Consent and is used to
                store whether or not a user has consented to the use of cookies.
                It does not store any personal data.
              </td>
            </tr>
            <tr></tr>
          </table>
        </div>
      ),
    },
    {
      key: "2",
      //   label: "This is panel header 2",
      label: (
        <div className="accordion-card">
          <div>Analytics Cookies</div>

          <div className="icon-header">
            <p>{btnText}</p>
            <Switch defaultChecked={false} onChange={onChange}></Switch>
          </div>
        </div>
      ),
      children: (
        <div className="accordion-table">
          <table className="table-container">
            <tr className="bold-heading">
              <th>Cookie</th>
              <th>Duration</th>
              <th>Description</th>
            </tr>
            <tr>
              <td> _ga</td>
              <td>2 Years</td>
              <td>
                This cookie is installed by Google Analytics. The cookie is used
                to calculate visitors...
              </td>
            </tr>
            <tr>
              <td>cookielawinfocheckbox-analytics</td>
              <td>11 months</td>
              <td>
                This cookie is set by GDPR Cookie Consent. The cookie is used to
                store the user consent for the cookies in the category
                "Analytics"
              </td>
            </tr>
            <tr></tr>
          </table>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="accordion-card">
          <div>Advertisement Cookies</div>

          <div className="icon-header">
            <p>{btnText2}</p>
            <Switch defaultChecked={false} onChange={onChange2}></Switch>
          </div>
        </div>
      ),
      children: (
        <div className="accordion-table">
          <table className="table-container">
            <tr className="bold-heading">
              <th>Cookie</th>
              <th>Duration</th>
              <th>Description</th>
            </tr>
            <tr>
              <td> cookielawinfocheckbox-necessary</td>
              <td>11 months</td>
              <td>
                The cookie is set by GDPR cookie consent to record the user
                consent for the cookies in the category "Necessary".
              </td>
            </tr>
            <tr>
              <td>viewed_cookie_policy</td>
              <td>11 months</td>
              <td>
                The cookie is set by the GDPR Cookie Consent and is used to
                store whether or not a user has consented to the use of cookies.
                It does not store any personal data.
              </td>
            </tr>
            <tr></tr>
          </table>
        </div>
      ),
    },
  ];
  return (
    <div className="modal1-container">
      <Modal
        title="Cookie Settings "
        centered="true"
        width={820}
        bordered={false}
        open={open}
        closable={false}
        footer={null}
        bodyStyle={{
          backgroundColor: "#153359",
          paddingBottom: "1vw",
          borderRadius: "0vw",
        }}
      >
        <div className="scrollable-container">
          <p className="coolkie-desc">
            We use different types of cookies to provide you the best possible
            experience in our website. Click on the categories below to learn
            more about their purpose. You can choose which cookies to on the
            categories below to learn more about their purpose. You can choose
            which cookies to accept and which ones to decline. For more
            information, please consult our &nbsp;
            <a href="/privacy-policy">Privacy Policy.</a>
          </p>
          <Collapse accordion items={items} />
        </div>
        {/* <div className="btn-container">
          <Button className="modal-btn" onClick={handleAcceptCookies}>
            Save Settings
          </Button>
          <Button className="modal-btn" onClick={handleAcceptCookies}>
            Accept All Setting
          </Button>
        </div> */}
      </Modal>
    </div>
  );
};

export default CookieSetting;
