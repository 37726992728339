/* eslint-disable no-lone-blocks */
import { Card, Col, Row, Space } from "antd";
import React, { useState, useEffect, useRef } from "react";
import "./capabilities.scss";
import { DownCircleOutlined } from "@ant-design/icons";
import { UpCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const Capabilities = () => {
  let location = useLocation();

  const rowTabpane = {
    backgroundImage: `url('/capability/blockchain.png')`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "auto",
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    if (location?.state?.isCardClicked) {
      console.log("location?.state?.isCardClicked", location?.state?.card);
      const element = document.getElementById("view");
      const headerOffset = 120;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [location]);

  const [cardState, setCardState] = useState({
    card1: location?.state?.card
      ? location?.state?.card === "genAI"
        ? true
        : false
      : true,
    card2: location?.state?.card === "AIdata" ? true : false,
    card3: location?.state?.card === "blockchain" ? true : false,
  });

  const handleCardClick = (idx) => {
    if (idx === 0) {
      setCardState({ card1: !cardState.card1, card2: false, card3: false });
    } else if (idx === 1) {
      setCardState({ card1: false, card2: !cardState.card2, card3: false });
    } else if (idx === 2) {
      setCardState({ card1: false, card2: false, card3: !cardState.card3 });
    }
  };
  return (
    <>
      <div className="capability_main">
        <Row className="row_container">
          <Col className="cap_bg_img_container">
            <div className="cap_container">
              <span className="span_title">Our Offerings</span>

              <span className="span_content">
                <p>
                  PerpetualBlock’s unique value proposition lies in the
                  integration of state-of-the-art generative AI, AI/ML, data
                  science technology to craft customized solution tailored to
                  various industries. We tackle the lack of trust and
                  transparency in traditional systems through our utilization of
                  blockchain and data engineering technologies. Furthermore, we
                  automate transactions resulting in cost savings and increased
                  operational efficiency
                </p>
              </span>
            </div>
          </Col>
        </Row>
        <div id="view">
          <Row style={rowTabpane} className="card_row_container">
            <Col
              lg={8}
              md={24}
              className="main_wrapper"
              style={{ width: "100%" }}
            >
              <div className="blockchain_container">
                <span className="blockchain_title">Our Offerings</span>
                <br></br>
                <br></br>
                <span className="blockchain_content">
                  Whether it's creating cutting-edge AI algorithms or building
                  secure and transparent blockchain ecosystems, we are here to
                  drive your business into the future
                </span>
              </div>

              <div className="card_container">
                <Space direction="vertical" size="middle">
                  <div>
                    <Card
                      bordered={false}
                      className={cardState?.card1 ? "selected-card" : "cards"}
                      size="small"
                      onClick={() => handleCardClick(0)}
                    >
                      <div className="icons">
                        <img
                          className="card_icon"
                          src="/assets/Frame 475.svg"
                          alt=""
                        ></img>
                      </div>
                      <div className="card_details">
                        <p className="card_title"> Generative AI </p>
                        <p className="card_detail">Our Bespoke Solution</p>
                      </div>
                    </Card>
                  </div>

                  <Card
                    bordered={false}
                    className={cardState?.card2 ? "selected-card" : "cards"}
                    size="small"
                    onClick={() => handleCardClick(1)}
                  >
                    <div className="icons">
                      <img
                        className="card_icon"
                        src="/assets/Frame 476.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="card_details">
                      <p className="card_title">AI & ML</p>
                      <p className="card_detail">Data Science Genius </p>
                    </div>
                  </Card>

                  <Card
                    bordered={false}
                    className={cardState?.card3 ? "selected-card" : "cards"}
                    size="small"
                    onClick={() => handleCardClick(2)}
                  >
                    <div className="icons">
                      <img
                        className="card_icon"
                        src="/assets/Frame 477.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="card_details">
                      <p className="card_title">Blockchain Solutions</p>
                      <p className="card_detail">Bespoke Blockchain Services</p>
                    </div>
                  </Card>
                </Space>
              </div>
            </Col>
            <Col lg={16} className="click_component">
              {cardState.card1 && (
                <div className="click_details">
                  <div className="headline">
                    <span className="title"> Generative AI | </span> &nbsp;
                    &nbsp;{" "}
                    <span className="title_desc"> Our Bespoke Solution </span>
                  </div>
                  <p className="sub_text">
                    PerpetualBlock stands as a generative AI champion,
                    showcasing remarkable capabilities in the realm of
                    creativity and innovation. With its advanced algorithms and
                    neural architecture, PerpetualBlock excels at generating
                    diverse and high-quality content across various domains,
                    solidifying its position as a frontrunner in the field of
                    generative artificial intelligence
                  </p>
                  <Row
                    gutter={[16, { xs: 24, sm: 24, md: 24, lg: 20 }]}
                    className="desc_cards"
                  >
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 970.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Content Generation
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Draft/Generate content, responses for a variety of use
                          cases
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 971.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Conversational Bots
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Engage in more natural and context-aware conversations
                          with users
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 972.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Virtual Assistant
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Provide support, perform tasks, and assist users and
                          customers with various activities and queries
                        </p>
                      </div>
                    </Card>

                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 973.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Analysis and Recommendations
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Conduct In-Depth analysis of available data sources to
                          derive insights
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 974.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Art and Creativity
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Use GenAI to explore new artistic styles, generate
                          digital artwork, or even collaborate with AI to
                          produce unique pieces
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 975.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Data Augmentation
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Generate synthetic data to augment existing datasets
                        </p>
                      </div>
                    </Card>
                  </Row>
                </div>
              )}
              {cardState.card2 && (
                <div className="click_details">
                  <div className="headline">
                    <span className="title"> AI & ML |</span> &nbsp; &nbsp;{" "}
                    <span className="title_desc"> Data Science Genius </span>
                  </div>
                  <p className="sub_text">
                    PerpetualBlock stands as a paragon of AI and ML-driven Data
                    Science brilliance. Our capabilities transcend the ordinary,
                    consistently pushing the boundaries of what is achievable in
                    the realm of data analysis and predictive modeling. Our
                    aptitude for extracting insights, making accurate
                    predictions, and unraveling complex patterns is
                    revolutionizing the landscape of data-driven decision-making
                  </p>
                  <Row
                    gutter={[16, { xs: 24, sm: 24, md: 24, lg: 32 }]}
                    className="desc_cards"
                  >
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 961.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Advanced Predictive Modeling
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Forecast trends and outcomes with exceptional
                          accuracy, providing invaluable insights for strategic
                          decision-making
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 962.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Unsupervised Learning Mastery
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Autonomously identify hidden patterns and
                          relationships within vast datasets, uncovering
                          valuable information
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 963.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Real-time Data Analysis
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Conduct real-time analysis of streaming data, enabling
                          rapid responses to dynamic changes and emerging
                          opportunities
                        </p>
                      </div>
                    </Card>

                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 964.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Intuitive Data Visualization
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Effortlessly communicate intricate findings to both
                          technical and non-technical stakeholders
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 965.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">NLP Integration</span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Extracts insights from text data, enabling a
                          comprehensive understanding of user sentiment and
                          trends
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 966.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Automated Decision Support
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          We deliver predictions and also suggest reliable
                          course of action
                        </p>
                      </div>
                    </Card>
                  </Row>
                </div>
              )}
              {cardState.card3 && (
                <div className="click_details">
                  <div className="headline">
                    <span className="title"> Blockchain Solutions |</span>{" "}
                    &nbsp; &nbsp;{" "}
                    <span className="title_desc">
                      Bespoke Blockchain Services
                    </span>
                  </div>
                  <p className="sub_text">
                    Experience in Building blockchain platforms, marketplaces,
                    payments platforms, Smart contracts for complex business use
                    cases using Public, Private and Hybrid Blockchain
                    implementations in various domains like Finance, Life
                    Science, Healthcare, Digital Payments and Decentralised
                    Identifiers
                  </p>
                  <Row
                    gutter={[16, { xs: 24, sm: 24, md: 24, lg: 32 }]}
                    className="desc_cards"
                  >
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 952.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Smart Contracts{" "}
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Smart contract development for complex business
                          problems and decision making on Ethereum, Solana and
                          other blockchains
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 953.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Public and Private
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Experience in building solutions on public, private
                          permissioned blockchains and hybrid implementations
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 954.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Decentralized Apps
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Domain agnostic technology stack with custom dApp
                          development for different use cases and industries
                        </p>
                      </div>
                    </Card>

                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 955.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Data Marketplaces
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Fostering transparent and efficient transactions while
                          preserving data privacy and ownership.
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 956.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">
                          Digital Payments
                        </span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          Enable fast, secure batch payment option (Crypto and
                          FIAT). Supports high throughput rates of 10K+ txns per
                          second
                        </p>
                      </div>
                    </Card>
                    <Card className="card_detailed">
                      <div className="card_title">
                        <img
                          className="desc_icon"
                          src="/assets/Group 957.svg"
                          alt="icon "
                        ></img>
                        <span className="properties_desc">NFT Marketplace</span>
                      </div>
                      <div className="properties_details">
                        <p className="desc_para">
                          White labelled customizable NFT Marketplace to create
                          your own branded digital assets
                        </p>
                      </div>
                    </Card>
                  </Row>
                </div>
              )}
            </Col>
          </Row>

          <Row className="card_row_container2">
            <div>
              <Row className="card_row_container2">
                <Col
                  lg={11}
                  md={24}
                  sm={24}
                  className="main_wrapper"
                  style={{ width: "100%" }}
                >
                  <div className="blockchain_container">
                    <div className="blockchain_title">Our Offerings</div>

                    <br></br>
                    <div className="blockchain_content">
                      Whether it's creating cutting-edge AI algorithms or
                      building secure and transparent blockchain ecosystems, we
                      are here to drive your business into the future
                    </div>
                  </div>

                  <div className="card_container">
                    <Space direction="vertical" size="middle">
                      <Card
                        bordered={false}
                        className={cardState?.card1 ? "selected-card" : "cards"}
                        size="small"
                        onClick={() => handleCardClick(0)}
                      >
                        <div className="icons">
                          <img
                            className="card_icon"
                            src="/assets/Frame 475.svg"
                            alt=""
                          ></img>
                        </div>
                        <div className="card_details">
                          <p className="card_title"> Generative AI</p>
                          <p className="card_detail">Our Bespoke Solution </p>
                        </div>
                        {/* <div> */}
                        <DownCircleOutlined className="drawer_icon" />
                        <UpCircleOutlined className="up_icon" />
                        {/* </div> */}
                      </Card>
                      {cardState.card1 && (
                        <Col lg={13} className="click_component">
                          <div className="click_details">
                            <p>
                              PerpetualBlock stands as a generative AI champion,
                              showcasing remarkable capabilities in the realm of
                              creativity and innovation. With its advanced
                              algorithms and neural architecture, PerpetualBlock
                              excels at generating diverse and high-quality
                              content across various domains, solidifying its
                              position as a frontrunner in the field of
                              generative artificial intelligence
                            </p>
                            <Row
                              gutter={[16, { xs: 24, sm: 24, md: 24, lg: 24 }]}
                              className="desc_cards"
                            >
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 970.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Content Generation
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Draft/Generate content, responses for a
                                    variety of use cases
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 971.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Conversational Bots
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Engage in more natural and context-aware
                                    conversations with users
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 972.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Virtual Assistant
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Provide support, perform tasks, and assist
                                    users and customers with various activities
                                    and queries
                                  </p>
                                </div>
                              </Card>

                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 973.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Analysis and Recommendations
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Conduct In-Depth analysis of available data
                                    sources to derive insights
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 974.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Art and Creativity
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Use generative AI to explore new artistic
                                    styles, generate digital artwork, or even
                                    collaborate with AI to produce unique pieces
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 975.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Data Augmentation
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Generate synthetic data to augment existing
                                    datasets
                                  </p>
                                </div>
                              </Card>
                            </Row>
                          </div>
                        </Col>
                      )}
                      <Card
                        bordered={false}
                        className={cardState?.card2 ? "selected-card" : "cards"}
                        size="small"
                        onClick={() => handleCardClick(1)}
                      >
                        <div className="icons">
                          <img
                            className="card_icon"
                            src="/assets/Frame 476.svg"
                            alt=""
                          ></img>
                        </div>
                        <div className="card_details">
                          <p className="card_title">AI & ML </p>
                          <p className="card_detail">Data Science Genius</p>
                        </div>
                        <DownCircleOutlined className="drawer_icon" />
                        <UpCircleOutlined className="up_icon" />
                      </Card>
                      {cardState?.card2 && (
                        <Col lg={13} className="click_component">
                          <div className="click_details">
                            <p>
                              PerpetualBlock stands as a paragon of AI and
                              ML-driven Data Science brilliance. Our
                              capabilities transcend the ordinary, consistently
                              pushing the boundaries of what is achievable in
                              the realm of data analysis and predictive
                              modeling. Our aptitude for extracting insights,
                              making accurate predictions, and unraveling
                              complex patterns is revolutionizing the landscape
                              of data-driven decision-making
                            </p>
                            <Row
                              gutter={[16, { xs: 24, sm: 24, md: 24, lg: 32 }]}
                              className="desc_cards"
                            >
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 961.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Advanced Predictive Modeling
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Forecast trends and outcomes with
                                    exceptional accuracy, providing invaluable
                                    insights for strategic decision-making
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 962.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Unsupervised Learning Mastery
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Autonomously identify hidden patterns and
                                    relationships within vast datasets,
                                    uncovering valuable information
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 963.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Real-time Data Analysis
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Conduct real-time analysis of streaming
                                    data, enabling rapid responses to dynamic
                                    changes and emerging opportunities
                                  </p>
                                </div>
                              </Card>

                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 964.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Intuitive Data Visualization
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Effortlessly communicate intricate findings
                                    to both technical and non-technical
                                    stakeholders
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 965.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    NLP Integration
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Extracts insights from text data, enabling a
                                    comprehensive understanding of user
                                    sentiment and trends
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 966.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Automated Decision Support
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    We deliver predictions and also suggest
                                    reliable course of action
                                  </p>
                                </div>
                              </Card>
                            </Row>
                          </div>
                        </Col>
                      )}
                      <Card
                        bordered={false}
                        className={cardState?.card3 ? "selected-card" : "cards"}
                        size="small"
                        onClick={() => handleCardClick(2)}
                      >
                        <div className="icons">
                          <img
                            className="card_icon"
                            src="/assets/block_card3.svg"
                            alt=""
                          ></img>
                        </div>
                        <div className="card_details">
                          <p className="card_title">Blockchain Solutions</p>
                          <p className="card_detail">
                            Bespoke Blockchain Services
                          </p>
                        </div>
                        <DownCircleOutlined className="drawer_icon" />
                        <UpCircleOutlined className="up_icon" />
                      </Card>
                      {cardState?.card3 && (
                        <Col lg={13} className="click_component">
                          <div className="click_details">
                            <p>
                              Experience in Building blockchain platforms,
                              marketplaces, payments platforms, Smart contracts
                              for complex business use cases using Public,
                              Private and Hybrid Blockchain implementations in
                              various domains like Finance, Life Science,
                              Healthcare, Digital Payments and Decentralised
                              Identifiers
                            </p>
                            <Row
                              gutter={[16, { xs: 24, sm: 24, md: 24, lg: 32 }]}
                              className="desc_cards"
                            >
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 952.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Smart Contracts
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Smart contract development for complex
                                    business problems and decision making on
                                    Ethereum, Solana and other blockchains
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 953.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Public and Private
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Experience in building solutions on public,
                                    private permissioned blockchains and hybrid
                                    implementations
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 954.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Decentralized Apps
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Domain agnostic technology stack with custom
                                    dApp development for different use cases and
                                    industries
                                  </p>
                                </div>
                              </Card>

                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 955.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Data Marketplaces
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Fostering transparent and efficient
                                    transactions while preserving data privacy
                                    and ownership
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 956.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    Digital Payments
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    Enable fast, secure batch payment option
                                    (Crypto and FIAT). Supports high throughput
                                    rates of 10K+ txns per second
                                  </p>
                                </div>
                              </Card>
                              <Card className="card_detailed">
                                <div className="card_title">
                                  <img
                                    className="desc_icon"
                                    src="/assets/Group 957.svg"
                                    alt="icon "
                                  ></img>
                                  <span className="properties_desc">
                                    NFT Marketplace
                                  </span>
                                </div>
                                <div className="properties_details">
                                  <p className="desc_para">
                                    White labelled customizable NFT Marketplace
                                    to create your own branded digital assets
                                  </p>
                                </div>
                              </Card>
                            </Row>
                          </div>
                        </Col>
                      )}
                    </Space>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Capabilities;
