import { Card, Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./home.scss";

const Home = () => {
  let navigate = useNavigate();

  return (
    <div className="home">
      <Row className="home-page-container">
        <Col sm={20} md={18} lg={13} xl={13} xxl={11} className="main-text">
          <div className="heading">
            Empowering Evolution through Innovative Technologies
          </div>
          <img className="line" src="/assets/line.svg" alt="" />
          <div className="sub-heading">
            <p className="sub-text">Generative AI, ML, Blockchain, and more </p>{" "}
            <p className="sub-text2">
              Unleashing Trust, Efficiency, and Creativity Across Industries.
            </p>
          </div>
          <button
            className="button button1"
            onClick={() => navigate("/offerings")}
          >
            Learn More
          </button>
        </Col>
      </Row>

      <div className="services">
        <div className="services-heading">Our Offerings</div>
        <Row
          gutter={[50, { xs: 24, sm: 24, md: 24, lg: 32 }]}
          className="services-card-row"
        >
          <Col className="card-wrap">
            <Card
              bordered={false}
              className="services-card"
              onClick={() =>
                navigate("/offerings", {
                  state: { isCardClicked: true, card: "blockchain" },
                })
              }
            >
              <img
                className="services-svg"
                src="/assets/blockchain.svg"
                alt=""
              />
              <div className="card-title">Blockchain Services</div>
              <div className="hr-tag"></div>
              <div className="card-text">
                Experience in Building blockchain platforms, marketplaces,
                payments platforms, Smart contracts for complex business use
                cases using Public, Private and Hybrid Blockchain
                implementations in various domains like Finance, Life Science,
                Healthcare, Digital Payments and Decentralized Identifiers.
              </div>
            </Card>
          </Col>
          <Col className="card-wrap">
            <Card
              bordered={false}
              className="services-card"
              onClick={() =>
                navigate("/offerings", {
                  state: { isCardClicked: true, card: "AIdata" },
                })
              }
            >
              <img className="services-svg" src="/assets/AI.svg" alt="" />
              <div className="card-title">AI & Data Services</div>
              <div className="hr-tag"></div>
              <div className="card-text">
                Our AI & ML capabilities across Image processing, NLP and
                Predictive Analytics enable us to aggregate and analyse
                structured and unstructured data. Our solutions are scalable,
                adaptable, and secure, accommodating changing business
                requirements and ensuring data privacy and protection.
              </div>
            </Card>
          </Col>
          <Col className="card-wrap">
            <Card
              bordered={false}
              className="services-card"
              onClick={() =>
                navigate("/offerings", {
                  state: { isCardClicked: true, card: "genAI" },
                })
              }
            >
              <img className="services-svg" src="/assets/genAI.svg" alt="" />
              <div className="card-title">Generative AI Solutions</div>
              <div className="hr-tag"></div>
              <div className="card-text">
                We utilize cutting-edge generative AI capabilities to create
                bespoke domain agnostic solutions designed for industry-specific
                needs. We remain at the forefront of technological advancements,
                continuously refining our AI models to assist with Q&A,
                information search, document drafting, and data summarization
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
