import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Career from "./pages/Career/Career";
import Capabilities from "./pages/Capabilities/Capabilities";
import ContactUs from "./pages/ContactUs/ContactUs";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import Policy from "./pages/Policy/Policy";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import { ToastContainer } from "react-toastify";
import CookiesModal from "./cookies/CookiesModal";
import CookieSetting from "./cookies/CookieSetting";
// import BlogDetails from "./pages/Blogs/BlogDetail";
import Blogs from "./pages/Blogs/Blogs";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/career" element={<Career />} />
          <Route path="/resources" element={<Blogs />} />
          {/* <Route path="/blogs/:id" element={<BlogDetails />} /> */}
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
        </Routes>
      </ScrollToTop>

      <Routes>
        <Route path="/offerings" element={<Capabilities />} />

        <Route path="/cookie-setting" element={<CookieSetting />}></Route>
      </Routes>
      <CookiesModal></CookiesModal>
    </div>
  );
}

export default App;
