import React, { useState } from "react";
import { Button, Card, Input, Row } from "antd";
import "./blog.scss";
// import "./resources.scss";
import { data } from "./data.js";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

const Blogs = () => {
  // const [searchValue, setSearchValue] = useState("");
  // const navigate = useNavigate();

  // const handleSearch = (value) => {
  //   setSearchValue(value?.trim()?.toLowerCase());
  // };

  // const filteredData = data.filter(
  //   (item) =>
  //     item.title.toLowerCase().includes(searchValue) ||
  //     item.image.toLowerCase().includes(searchValue) ||
  //     item.discription.toLowerCase().includes(searchValue) ||
  //     item.details.toLowerCase().includes(searchValue)
  // );

  return (
    <>
      <div className="blog">
        <div className="blogs-heading">Resources</div>
     

        {/* <div className="subscribe-input">
          <Input
            className="input-box"
            placeholder="Search Blog By Key Words"
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          ></Input>
          <img src="/assets/Search.svg" alt="send" className="image-icon" />
        </div> */}

        <Row
          // gutter={[16, { xs: 24, sm: 24, md: 24, lg: 32 }]}
          className="blogs-row"
        >
          {/* {filteredData?.map((item) => { */}
          {/* return ( */}
          <Card
            // key={item?.id}
            className="blog-card"
            bordered={false}
          >
            <div>
              <img
                className="blog-img"
                // src={item.image ? item.image : "/assets/blog-img.png"}
                src={"/assets/resource1.png"}
                alt=""
              />
              <img className="pb-logo" src="/assets/pb-logo.svg" alt="" />
            </div>
            <div className="blog-title">
              {/* {item?.title} */}
              Weekly Newsletter - 15th Sep 2023
            </div>
            <div className="blog-disc">
              {/* {item?.discription} */}
              We embark on a thrilling Gen AI journey, bringing you the latest
              in innovation and technology trends!
            </div>

            <div className="btn-wrap">
              <Button
                className="blog-btn"
                // onClick={() =>
                //   navigate(`/blogs/${item.id}`, {
                //     state: { data: item },
                //   })
                // }
              >
                {" "}
                <Link
                  to={
                    "https://www.linkedin.com/pulse/explore-future-gen-ai-your-weekly-passport-innovation?trk=public_post_feed-article-content"
                  }
                  target="_blank"
                >
                  {" "}
                  Read More <DoubleRightOutlined />
                </Link>
              </Button>
            </div>
          </Card>
          {/* ); */}
          {/* })} */}
          {/* {filteredData.length === 0 && <div>No data found</div>} */}
        </Row>
      </div>
    </>
  );
};

export default Blogs;
