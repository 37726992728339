import React from "react";
import { Row, Col } from "antd";
import "./AboutUs.scss";
const AboutUs = () => {
  const rowStyle = {
    marginTop: "5vw",

    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const rowStyle2 = {
    backgroundImage: `url('/assets/About_bg.jpg')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginTop: "0%",
  };

  const colStyle3 = {
    Image: `url('/assets/Frame3.png')`,
    backgroundPosition: "center",
  };
  const colStyle5 = {
    Image: `url('/assets/Frame5.png')`,
    backgroundPosition: "center",
  };

  return (
    <>
      <div className="about-us">
        <Row style={rowStyle} className="row_container_1">
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className="col1">
              <div className="col1_content">
                <span className="span_ihe">
                  Increasing Humanity's Equitability
                </span>
                <br></br>
                <span className="span_ihc_content">
                  <p>
                    PerpetualBlock is a technology company that specializes in
                    leveraging emerging technologies such as Generative
                    Artificial Intelligence (AI), Machine Learning (ML),
                    Blockchain, and Data Engineering. We focus on solving
                    critical challenges related to trust, transparency,
                    inefficiency, and creativity across various industries.
                  </p>
                  <p>
                    Our innovative solutions address key problems by enabling
                    novel approaches to problem-solving. With the goal of
                    driving industry evolution, we empower businesses to stay
                    ahead of advancements.
                  </p>
                </span>
                <br></br>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={rowStyle2} className="row_container_2">
          <Col xs={24} sm={24} md={24} lg={24} className="values_container">
            <div className="col1">
              <div className="col1_content">
                {" "}
                <span className="span_ihe" style={{ color: "#58FF9E" }}>
                  Our Values
                </span>
                <br></br>
                <span className="span_ihc_content">
                  We are driven by our core values, which act as guiding
                  principles in every action we take as it’s easy to be
                  misguided from our core values and falter in delivering our
                  vision in today’s world of reducing product cycles, attention
                  span. These help us provide maximum value to our customers
                  building confidence at each step of the process.
                </span>
                <br></br>
              </div>

              <div className=" icons_container">
                <ul className="icons_wrp">
                  <li className="prop_list">
                    <span className="gallery_img">
                      <img
                        className="icon_fit"
                        src="/assets/Transparancy.svg"
                        alt="transparency icon "
                      ></img>
                    </span>
                    <span className="span_content">Transparency</span>
                  </li>
                  <li className="prop_list">
                    <span className="gallery_img">
                      <img
                        className="icon_fit"
                        src="/assets/Accessibility.svg"
                        alt="accesinility icon"
                      ></img>
                    </span>
                    <span className="span_content"> Accessibility</span>
                  </li>
                  <li className="prop_list">
                    <span className="gallery_img">
                      <img
                        className="icon_fit"
                        src="/assets/Group 979.svg"
                        alt="affordability icon"
                      ></img>
                    </span>
                    <span className="span_content">Continuous Growth</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="tranperency_container">
          <Col sm={24} md={24} lg={11} style={colStyle3}>
            <img
              className="transperency_img"
              src={"/assets/Frame 494 (1).png"}
              alt="Paris"
            ></img>
            <img
              className="trans_images"
              src={"/assets/technology.png"}
              alt="Perpetualblock"
            ></img>
          </Col>
          <Col sm={24} md={24} lg={13}>
            <div className="col3">
              <span className="content_header">Transparency</span>

              <span className="content_details">
                <p>
                  For us transparency is not just a buzzword; it's a core value
                  that drives everything we do. We believe that trust is the
                  foundation of strong relationships, both with our clients and
                  our team members. That's why we are committed to being open
                  and honest in all of our dealings, from project development to
                  pricing structures. Our dedication to transparency ensures
                  that you can have complete confidence in our services and the
                  products we deliver. We're not just building technology; we're
                  building trust.
                </p>
              </span>
            </div>
          </Col>
        </Row>
        <Row
          className="tranperency_container2"
          style={{ backgroundColor: "#15335921" }}
        >
          <Col sm={24} md={24} lg={11}>
            <div className="col4">
              <span className="content_header_acs">Accessibility</span>
              <br></br>
              <br></br>
              <span className="content_details_acs">
                Accessibility is the fundamental core value that underpins our
                entire ethos. We are unwavering in our commitment to creating
                technology that is inclusive and user-friendly for all. When you
                choose us, you're not just partnering with a tech company;
                you're joining a movement to make technology accessible to all,
                and that's a commitment we stand by, every step of the way. We
                rigorously adhere to accessibility standards, from web design to
                software development, because we believe that technology should
                empower, not exclude.
              </span>
            </div>
          </Col>
          <Col sm={24} md={24} lg={13}>
            <img
              className="transperency_img2"
              src={"/assets/Frame 493 (1).png"}
              alt="Perpetualblock"
            ></img>
            <img
              className="trans_images2"
              src={"/assets/biometrics.png"}
              alt="Perpetualblock"
            ></img>
          </Col>
        </Row>
        <Row className="tranperency_container">
          <Col sm={24} md={24} lg={11} style={colStyle5}>
            <img
              className="transperency_img"
              src={"/assets/Frame 479 (1).png"}
              alt="Paris"
              style={{ paddingLeft: "1vw" }}
            ></img>
            <img
              className="trans_images"
              src={"/assets/retinal.png"}
              alt="Perpetualblock"
            ></img>
          </Col>
          <Col sm={24} md={24} lg={13}>
            <div className="col3">
              <span className="content_header">Continuous Growth</span>
              <br></br>
              <br></br>
              <span className="content_details">
                We are committed to a culture of perpetual learning, innovation,
                and adaptation. Our team thrives on challenges and embraces
                change as an opportunity to grow. We always strive for
                excellence and develop cutting-edge solutions to keep ourselves
                ahead in a rapidly changing digital world. When you partner with
                us, you're not just getting a tech solution; you're getting a
                partner dedicated to pushing the boundaries of what technology
                can achieve.
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AboutUs;
