import { Col, Input, Row, Spin } from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import send from "../.././assetsimage/send.svg";
import "./contactus.scss";
import ContactUsForm1 from "./contactUsForm1";
const validateEmail = (email1) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email1);
};

const ContactUs = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const notify = () => {
    toast.success("Newsletter Subscribed successfully", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleChange = (event) => {
    setFormData((prevData) => ({
      ...formData,
      email: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formDataDetail = new FormData(form);
    if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email address", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    setLoading(true);
    try {
      // "https://formsubmit.co/prathamesh.dutondkar@perpetualblock.io",
      const response = await fetch(
        "https://formsubmit.co/info@perpetualblock.io",
        {
          // const response = await fetch("https://sheetdb.io/api/v1/c1535k9tg0ab1", {

          method: "POST",
          body: formDataDetail,
        }
      );

      if (response.ok) {
        setLoading(false);

        notify();
        setFormData({
          email: "",
        });
      } else {
        setLoading(false);
        console.error("Form submission error:", response);
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <>
      <div className="contact-us">
        <div className="contact-us-container">
          <div className="contact-us-heading">
            <h2>Contact Us</h2>
          </div>
          <div className="location-container">
            <div className="location-card-1">
              {" "}
              <img
                src="/assets/pune.png"
                alt="pune_office"
                className="swizerland-image"
              />
              <div className="location-text">
                <img
                  src="/assets/location.svg"
                  alt="location"
                  className="location-icon"
                />
                <p>
                  India: PerpetualBlock Technologies Pvt Ltd. 7th Floor, Midas
                  Tower, Hinjewadi Phase 1 - Pune 411057
                </p>
              </div>
            </div>
            <div className="location-card-2">
              <img
                src="/assets/swizerlandoffice.png"
                alt="swizerland_office"
                className="swizerland-image"
              />
              <div className="location-text">
                <img
                  src="/assets/location.svg"
                  alt="location"
                  className="location-icon"
                />
                  <p>
                  {" "}
                  Switzerland: Perpetual Block AG, <br />
                  {/* Gotthardstrasse 28, Postfach
                  7163, 3602 Zug */}
                  Maschinengasse 8, 6330 Cham, <br />
                  Switzerland
                </p>
              </div>
            </div>
          </div>
          <Row className="contact-form-container">
            <Col
              xxl={14}
              xl={14}
              lg={14}
              md={0}
              sm={0}
              xs={0}
              className="contact-form-container-1"
            ></Col>
            <Col
              xxl={10}
              xl={10}
              lg={10}
              md={24}
              sm={24}
              xs={24}
              className="form-detail"
            >
              <div className="form-detail-title">Lets Connect</div>
              <ContactUsForm1></ContactUsForm1>
            </Col>
          </Row>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_captcha" value="false" />
            <div className="subscribe-news-letter">
              <div className="subscribe-new-1">
                <label className="subscribe-letter">
                  Subscribe For Newsletter
                </label>
              </div>
              <div className="subscribe-input">
                <Input
                  type="email"
                  className="input-box"
                  placeholder="Your Mail"
                  name="subcriber_email_id"
                  autoComplete="off"
                  required
                  value={formData.email}
                  onChange={(value) => handleChange(value)}
                />
                <button type="submit" style={{ display: "contents" }}>
                  {loading ? (
                    <Spin size="medium" className="image-icon" />
                  ) : (
                    <img src={send} alt="send" className="image-icon" />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
