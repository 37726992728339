import { Input } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import send from "../.././assetsimage/sendHome.svg";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  const handleSendClick = () => {
    if (validateEmail(email)) {
      toast.success("Newsletter subscribed successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      setEmail("");
    } else {
      toast.error("Please enter a valid email address", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };
  const validateEmail = (email1) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email1);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="footer">
        <div className="footer-content">
          <div className="reach-us">
            <div className="reach-us-title">Reach Us</div>

            <div className="reach-us-detail">
              <span className="location-logo">
                <img
                  src="/assets/location-pin.svg"
                  alt="location"
                  className=" location-svg"
                />
                <span className="country">India</span>
              </span>

              <div className="location-address">
                <span
                  style={{ color: "white", borderRight: "2px solid white" }}
                >
                  {" "}
                </span>
                <span className="locations">
                  PerpetualBlock Technologies Pvt Ltd. 7th Floor, Midas Tower,
                  Hinjewadi Phase 1 , Pune 411057
                </span>
              </div>
            </div>
            <div className="reach-us-detail">
              <span className="location-logo">
                <img
                  src="/assets/location-pin.svg"
                  alt="location"
                  className=" location-svg"
                />
                <span className="country"> Switzerland</span>
              </span>
              <div className="location-address">
                <span className="locations">
                  Perpetual Block AG, Maschinengasse 8, 6330 Cham, Switzerland
                </span>
              </div>
            </div>
          </div>
          <div
            className="binder"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="navigate-container">
              <div className="navigate-title">Navigate</div>
              <div className="footer-text">
                <Link to="/" className="link">
                  Home
                </Link>

                <Link to="/aboutus" className="link">
                  About Us
                </Link>

                <Link to="/offerings" className="link">
                  Our Offerings
                </Link>

                <Link to="/career" className="link">
                  Careers
                </Link>
                <Link to="/resources" className="link">
                  Resources
                </Link>

                <Link to="/contactus">Contact US</Link>
              </div>
            </div>
            <div className="connent-us-container">
              <div className="title">Connect </div>
              <div className="footer-icon">
                <Link
                  to={"https://www.linkedin.com/company/perpetual-block-ag/"}
                >
                  <img
                    className="social-icon"
                    src={"/assets/Group 985.svg"}
                    alt=""
                  />
                </Link>
                <Link to={"https://instagram.com/lifeatperpetualblock/"}>
                  <img
                    className="social-icon"
                    src={"/assets/Group (2).svg"}
                    alt=""
                  />
                </Link>

                <a href="mailto:info@perpetualblock.io">
                  <img
                    className="social-icon"
                    src="/assets/mail-pin.svg"
                    style={{ height: "80%" }}
                    alt="mail"
                  />
                </a>
              </div>
              <div className="mail-container" onClick={() => navigate("/")}>
                <span className="mail-img">
                  <img
                    className="logo-pb"
                    src="/assets/company-logo.svg"
                    alt="mail"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="copyright">
          &copy; 2023 Perpetual Block AG. All rights reserved.
          <span className="seperator "> &nbsp; | &nbsp; </span>{" "}
        </p>
        <p className="copyright-condition">
          <span
            className="policy"
            onClick={() => navigate("/terms-and-conditions")}
          >
            Terms and Conditions
          </span>{" "}
          <span class Name="line-spacing" style={{ color: "white " }}>
            {" "}
            &nbsp; | &nbsp;{" "}
          </span>{" "}
          <span className="policy" onClick={() => navigate("/privacy-policy")}>
            Privacy Policy
          </span>
        </p>
      </div>
    </>
  );
};

export default Footer;
